//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { microscopeIcon, weightLifterIcon, hairIcon, dnaIcon, virusIcon } from "@debionetwork/ui-icons";
import { mapState } from "vuex";
import { getOrderDetail } from "@/common/lib/api";
import { queryDnaSamples } from "@debionetwork/polkadot-provider";
import { ORDER_STATUS_DETAIL } from "@/common/constants/status";
import { formatPrice } from "@/common/lib/price-format.js";
export default {
  name: "OrderHistoryDetail",
  data: () => ({
    microscopeIcon,
    hairIcon,
    dnaIcon,
    virusIcon,
    weightLifterIcon,
    banner: "",
    selectedIcon: weightLifterIcon,
    showDetail: false,
    dnaSample: {},
    e1: 1,
    status: {
      status: "",
      name: "",
      detail: "",
      size: 0,
      viewBox: ""
    },
    myTest: {},
    prices: {
      servicePrice: 0,
      qcPrice: 0,
      currency: ""
    },
    isLoading: false
  }),
  async mounted() {
    if (this.$route.params.id) {
      await this.getOrderDetail();
      await this.checkOrderDetail();
      this.iconSwitcher();
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.metamask.web3
    }),
    computeDifferenceAmount() {
      return this.prices.servicePrice - this.prices.qcPrice;
    }
  },
  methods: {
    async getOrderDetail() {
      this.isLoading = true;
      try {
        this.myTest = await getOrderDetail(this.$route.params.id);
        this.dnaSample = await queryDnaSamples(this.api, this.myTest.dna_sample_tracking_id);
        this.prices.servicePrice = this.formatPrice(this.myTest.service_info.prices_by_currency[0].total_price, this.myTest.service_info.prices_by_currency[0].currency.toUpperCase());
        this.prices.qcPrice = this.formatPrice(this.myTest.service_info.prices_by_currency[0].additional_prices[0].value, this.myTest.service_info.prices_by_currency[0].currency.toUpperCase());
        this.prices.currency = this.myTest.service_info.prices_by_currency[0].currency.toUpperCase();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    toViewResult() {
      this.$router.push({
        name: "test-result",
        params: {
          id: this.$route.params.id
        }
      });
    },
    isRejected(border) {
      if (border) return this.dnaSample.status === "Rejected" && "border-error";else return this.dnaSample.status === "Rejected" && "error";
    },
    closeModal() {
      this.showDetail = false;
    },
    iconSwitcher() {
      switch (this.myTest.service_info.name) {
        case "Covid-19 Testing":
          this.selectedIcon = virusIcon;
          break;
        case "Whole Genome Sequencing":
          this.selectedIcon = dnaIcon;
          break;
        case "Diet":
          this.selectedIcon = weightLifterIcon;
          break;
        case "Skin":
          this.selectedIcon = hairIcon;
          break;
        case "SNP Microarray":
          this.selectedIcon = dnaIcon;
          break;
        default:
          this.selectedIcon = weightLifterIcon;
          break;
      }
    },
    checkOrderDetail() {
      const statusDetail = ORDER_STATUS_DETAIL[this.dnaSample.status.toUpperCase()];
      if (this.dnaSample.status === "Rejected") {
        const refundAmount = formatPrice(this.myTest.service_info.prices_by_currency[0].total_price, this.myTest.service_info.prices_by_currency[0].currency.toUpperCase()) - formatPrice(this.myTest.service_info.prices_by_currency[0].additional_prices[0].value, this.myTest.service_info.prices_by_currency[0].currency.toUpperCase());
        const {
          banner,
          name,
          detail,
          bannerSize,
          viewBox,
          e1
        } = statusDetail(refundAmount);
        this.banner = banner;
        this.status = {
          name,
          detail,
          size: bannerSize,
          viewBox
        };
        this.e1 = e1;
        return;
      }
      const {
        banner,
        name,
        detail,
        bannerSize,
        viewBox,
        e1
      } = statusDetail;
      this.banner = banner;
      this.status = {
        name,
        detail,
        size: bannerSize,
        viewBox
      };
      this.e1 = e1;
    }
  }
};